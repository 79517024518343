import { BLOG_URL, DESCRIPTION } from "../utils/constants";
import { StaticQuery, graphql } from "gatsby";

import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import LayoutMain from "./main-layout";
import Nav from "../components/nav";
import React from "react";
import RootLayout from "./root-layout";

const Layout = ({ canonical, children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      return (
        <RootLayout>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={DESCRIPTION} />
            {canonical ? (
              <link rel="canonical" href={`${BLOG_URL}blog/${canonical}`} />
            ) : (
              <link rel="canonical" href={BLOG_URL} />
            )}
            <link
              href="https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap"
              rel="stylesheet"
            />

            <meta property="og:image" content={`${BLOG_URL}media-image.png`} />
            <meta property="og:description" content={DESCRIPTION} />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:type" content={"website"} />
            <meta property="og:url" content={BLOG_URL} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@meterbolic" />
            <meta name="twitter:creator" content="@meterbolic" />
            <meta name="twitter:image" content={`${BLOG_URL}media-image.png`} />
            <link
              href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Nav />
          <LayoutMain>{children}</LayoutMain>
          <Footer />
        </RootLayout>
      );
    }}
  />
);

export default Layout;
